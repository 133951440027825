import { useState, useEffect, useMemo } from "react";
import './App.css';

import { useAccount, useConnect, useDisconnect, useContractWrite, useContractRead, useNetwork, useContract } from "@starknet-react/core";
import erc20ABI from "./abis/erc20";
import erc404ABI from "./abis/erc404";

const CONTRACT_ADDRESS = "0x02148eb56fce7facaa58713eeb805118e8519f9b067cd5eebded0d1a6734ea2d";

function App() {
  const { account, address, status } = useAccount();
  const { connect, connectors } = useConnect();
  const { disconnect } = useDisconnect();
  const { chain } = useNetwork();

  const [connectModal, setConnectModal] = useState(false); 

  const [mintAmount, setMintAmount] = useState(1);

  const [mintCost, setMintCost] = useState(0);
  const [ethBalance, setEthBalance] = useState(0);
  const [maxMint, setMaxMint] = useState(0);
  const [minted, setMinted] = useState(0);
  const [supply, setSupply] = useState(0);
  const [decimals, setDecimals] = useState(18);
  
  const etherContract = useContract({
    abi: erc20ABI,
    address: chain.nativeCurrency.address,
  }).contract;

  const contract = useContract({
    abi: erc404ABI,
    address: CONTRACT_ADDRESS,
  }).contract;

  const formattedAddress = (add) => 
   `${add.slice(0, 6)}...${add.slice(-4)}`;

  const formatAmount = (amount) =>
    (amount).toFixed(4);

   const callsRead = useMemo(() => {
    if(!address || !etherContract || !contract) return [];
    const calls = [
      etherContract.callStatic["balance_of"](address),
      contract.callStatic["get_max_mint_per_tx"](),
      contract.callStatic["get_mint_cost"](),
      contract.callStatic["get_total_supply"](),
      contract.callStatic["get_decimals"](),
      contract.callStatic["get_minted"](),
    ];
    return calls;
  }, [etherContract, address, contract]);

  useContractRead({
    calls: callsRead,
  });

  useEffect(() => {
    if (callsRead.length < 6) return;
    callsRead[0].then((data) => {
      setEthBalance(Number(data) / (10 ** 18));
    });
    callsRead[1].then((data) => {
      setMaxMint(Number(data));
    });
    callsRead[2].then((data) => {
      setMintCost(Number(data) / (10 ** 18));
    });
    callsRead[3].then((data) => {
      setSupply(Number(data));
    });
    callsRead[4].then((data) => {
      setDecimals(Number(data));
    });
    callsRead[5].then((data) => {
      setMinted(Number(data));
    });
  }, [callsRead]);

   const callsMint = useMemo(() => {
    if (!address || !etherContract|| !contract) return [];
    const txs = [
      etherContract.populateTransaction["approve"](CONTRACT_ADDRESS, mintCost * 10**18 * mintAmount),
      contract.populateTransaction["mint"](mintAmount),
    ];
    return txs;
  }, [etherContract, contract, address, mintAmount, mintCost]);

  const {
    writeAsync,
    data,
    isPending,
  } = useContractWrite({
    calls: callsMint,
  });

  const writeMint = writeAsync;

  return (
    <>
    <header>
      <img src="/title.png" width="60%" />
      <h1>ERC 404</h1>
      <h2>Zeus coming on Starknet to fight Pandora</h2>
      <h3>No private sale. No whitelist. No bullshit.</h3>
      <p>Minting provides liquidity automatically</p>
    </header>
    <div className="app">
    {
      (status === "disconnected" && !connectModal) && (
        <button onClick={() =>setConnectModal(true)}>
          Connect Wallet
        </button>
      )
    }
    {
        status === "disconnected" && connectModal && (
            <div>
              <h2>Connect to Starknet</h2>
              <p>Choose your wallet:</p>
              <ul>
                {connectors.map((connector) => (
                  <li key={connector.id}>
                    <button onClick={() => { connect({ connector }); setConnectModal(false);  }}>
                      {connector.name}
                    </button>
                  </li>
                ))}
              </ul>
              <button onClick={() => setConnectModal(false)}>
                Cancel
              </button>
            </div>
          )
      }
      {
        status != "disconnected" &&
        <div className="mint">
          <p>Account: {formattedAddress(address)} | ETH Balance: {formatAmount(ethBalance)}</p>
          <button onClick={() => disconnect()}>
            Disconnect
          </button>
          <p>Mint cost: {mintCost*mintAmount}</p>
          <div>
            <span>1</span>
            <input 
            type="range" 
            className="tomintbar"
            value={mintAmount} 
            min={1}
            max={maxMint && ethBalance && mintCost ? Math.min(maxMint, Math.floor(ethBalance / mintCost)) : 1}
            onChange={(e) => setMintAmount(e.target.value)}></input>
            <span>{Math.min(maxMint, Math.floor(ethBalance / mintCost))}</span>
          </div>
          
          <button onClick={() => writeMint()}>
            Mint {mintAmount} tokens
          </button>
  
          {
            supply/10**decimals > minted && 
            (
              <>
              <p>Mint in progress:</p>
              <div>
                {minted?minted:0}
                <input type="range" className="progress" value={minted?minted:0} min={0} max={supply/10**decimals} readOnly></input>
                {supply/10**decimals}
              </div>
              </>
            )
            ||
            (
              <p>Sold out!</p>
            )
          }
        </div>
      }
    </div>
    <footer>
      <p>ERC 404 coming on Jediswap</p>
      <p>No roadmap. Just Fun.</p>
      <p><a href="https://twitter.com/Zeus404_erc" target="_blank">twitter</a> <a href="https://t.me/Zeus404erc" target="_blank">Telegram</a></p>
    </footer>
    </>
  );
}

export default App;
